import "../scss/style.scss";

/**
 * @babel/polyfill の利用
 * ※ PromiseやArray.includes()など、構文ではない機能的な仕様を利用するために必要
 */
import "@babel/polyfill";

import lazysizes from "lazysizes"
import unveil from "lazysizes/plugins/unveilhooks/ls.unveilhooks.min.js"

// if ('NodeList' in window && !NodeList.prototype.forEach) {
//     NodeList.prototype.forEach = function (callback, thisArg) {
//         thisArg = thisArg || window;
//         for (var i = 0; i < this.length; i++) {
//             callback.call(thisArg, this[i], i, this);
//         }
//     };
// }

//import Accordion from "./lib/Accordion";
import Drawer from "./lib/Drawer";
import Headroom from "./lib/Headroom";
import ResponsiveImages from "./lib/ResponsiveImages";
import switchViewport from "./lib/switchViewport";
import Modal from "./lib/Modal";
//import Tab from "./lib/Tab";

//new Accordion();
new Drawer();
new Headroom();
new ResponsiveImages();
new switchViewport();
new Modal();
//new Tab();