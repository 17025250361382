export default class {
    constructor() {
        this.modalWrap = '.js-modal';
        this.modalClose = '.js-modal-close';
        this.modalBg = '.js-modal-bg';
        this.modalTrigger = '.js-modal-trigger';
        this.modalContent = '.js-modal-content';
        this.init();
    }
    init() {
        const modalW = document.querySelector(this.modalWrap);
        const modalClose = document.querySelector(this.modalClose);
        const modalBg = document.querySelector(this.modalBg);
        const modalTrigger = document.querySelectorAll(this.modalTrigger);
        const modalContent = document.querySelectorAll(this.modalContent);
        console.log(home);

        const modalTriggerArray = Array.prototype.slice.call(modalTrigger);
        const modalContArray = Array.prototype.slice.call(modalContent);

        if( modalW ){
            modalTriggerArray.forEach((element, index) => {
                element.addEventListener('click', () => {
                    for (let i = 0; i < modalContent.length; i++){
                        modalContArray[i].classList.remove('is-active');
                    }
                    modalW.classList.add('is-show');
                    modalContent[index].classList.add('is-active');
                });
            });
            modalClose.addEventListener('click', () => {
                this.closeAction();
            });
            modalBg.addEventListener('click', () => {
                this.closeAction();
            });
        }
    }

    closeAction() {
        const home = document.querySelector(this.home);
        const modalW = document.querySelector(this.modalWrap);
        const modalContent = document.querySelectorAll(this.modalContent);
        const modalContArray = Array.prototype.slice.call(modalContent);

        for (let i = 0; i < modalContent.length; i++){
            modalContArray[i].classList.remove('is-active');
        }
        modalW.classList.remove('is-show');
    }
}